<template>
  <div class="h-screen main">
    <nav-bar />
    <div class="bg-blue-100 body flex">
      <div>
        <Sidebar />
      </div>
      <div class="bg-primary w-full full-height custom-scroll">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NavBar from "/src/components/Navbar.vue"; // @ is an alias to /src
import Sidebar from "/src/components/Sidebar.vue";
export default {
  name: "INDEX",
  components: {
    NavBar,
    Sidebar,
  },
  data() {
    return {
      timeoutInMS: 300000, // 5 minutes -> 5 * 60 * 1000
      timeoutId: null,
    };
  },
  methods: {
    ...mapActions({ loadSettings: "settings/getSettings" }),
    ...mapActions({ getDetails: "auth/getDetails" }),
    ...mapActions({ logout: "auth/logout" }),
    handleInactive() {
      // Here you want to logout a user and/or ping your token
      this.logout();
    },

    startTimer() {
      // setTimeout returns an ID (can be used to start or clear a timer)
      this.timeoutId = setTimeout(this.handleInactive, this.timeoutInMS);
    },

    resetTimer() {
      clearTimeout(this.timeoutId);
      this.startTimer();
    },

    setupTimers() {
      document.addEventListener("keypress", this.resetTimer, false);
      document.addEventListener("mousemove", this.resetTimer, false);
      document.addEventListener("mousedown", this.resetTimer, false);
      document.addEventListener("touchmove", this.resetTimer, false);

      this.startTimer();
    },
  },
  created() {
    this.loadSettings();
    this.getDetails();
    this.setupTimers();
  },
};
</script>
<style lang="scss" scoped>
.body {
  // grid-template-columns: 1fr 19fr;
  // height: 92vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: #ebf2fa;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #7d7878;
    border-radius: 12px;
  }
}
</style>
