<template>
  <div
    :class="`h-side my-sidebar ${
      state ? 'absolute lg:static' : 'hidden'
    } lg:block`"
  >
    <div id="logo" class="flex justify-center">
      <img
        alt="Coat of Arms of Rwanda logo"
        src="../assets/Coat_of_arms_of_Rwanda.png"
      />
    </div>
    <ul class="list-reset text-left">
      <router-link
        tag="li"
        class="link-item colored-link"
        class-active="active"
        to="/"
        exact
      >
        <img
          src="../assets/icons/white-home-icon.svg"
          alt="home-icon"
          v-if="this.$route.path == '/'"
        />

        <img src="../assets/icons/blued-home-icon.svg" alt="home-icon" v-else />

        <span class="menu-link"> Home </span>
      </router-link>
      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name !== 'CLUSTER_USER' &&
          $store.state.auth.user.roles[0].name !== 'ADMIN' &&
          $store.state.auth.user.roles[0].name !== 'CLUSTER_ADMIN' &&
          $store.state.auth.user.roles[0].name !== 'MINISTRY' &&
          $store.state.auth.user.roles[0].name !== 'GAZETTE_PROCESSOR' &&
          canAccessMeetings
        "
        class="dropdown"
        id="dropdown"
      >
        <h6 class="link-divider">{{ linkDivider[0] }}</h6>
        <router-link
          v-if="
            $store.state.auth.user.roles[0].name !== 'VIEWER' ||
            fields['profiles']
          "
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/appointments"
          exact
        >
          <img
            src="../assets/icons/active-calendar.svg"
            alt=""
            v-if="this.$route.path == '/appointments'"
          />
          <img
            src="../assets/icons/calendar-icon.svg"
            alt="appointment-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[0] }}
          </span>
        </router-link>
      </li>

      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name !== 'CLUSTER_USER' &&
          $store.state.auth.user.roles[0].name !== 'ADMIN' &&
          $store.state.auth.user.roles[0].name !== 'CLUSTER_ADMIN' &&
          $store.state.auth.user.roles[0].name !== 'MINISTRY' &&
          $store.state.auth.user.roles[0].name !== 'GAZETTE_PROCESSOR' &&
          fields['profile_reports'] &&
          canAccessMeetings
        "
        class="dropdown"
        id="dropdown"
      >
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/appointment-reports"
          exact
        >
          <img
            src="../assets/icons/report.png"
            alt=""
            v-if="this.$route.path == '/reports'"
          />
          <img
            src="../assets/icons/report_2.png"
            alt="appointment-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[5] }}
          </span>
        </router-link>
      </li>

      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name !== 'SECRETARY' &&
          $store.state.auth.user.roles[0].name !== 'ADMIN' &&
          canAccessDocuments
        "
        class="dropdown"
        id="dropdown"
      >
        <h6 class="link-divider">{{ linkDivider[1] }}</h6>
        <router-link
          v-if="
            !['MINISTRY', 'GAZETTE_PROCESSOR'].includes(
              $store.state.auth.user.roles[0].name
            ) &&
            ($store.state.auth.user.roles[0].name !== 'VIEWER' ||
              fields['documents'])
          "
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/documents"
          exact
        >
          <img
            src="../assets/icons/folder-3-fill.svg"
            alt=""
            v-if="this.$route.path == '/documents'"
          />
          <img
            src="../assets/icons/document-icon.svg"
            alt="document-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[1] }}
          </span>
        </router-link>

        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/gazette-processsing-documents"
          exact
          v-if="
            $store.state.auth.user &&
            $store.state.auth.user.roles[0].name === 'GAZETTE_PROCESSOR'
          "
        >
          <img
            src="../assets/icons/folder-3-fill.svg"
            alt=""
            v-if="this.$route.path == '/gazette-processsing-documents'"
          />
          <img
            src="../assets/icons/document-icon.svg"
            alt="document-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[9] }}
          </span>
        </router-link>

        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/ministry-documents"
          exact
          v-if="
            $store.state.auth.user &&
            ['MINISTRY', 'CLUSTER_ADMIN'].includes(
              $store.state.auth.user.roles[0].name
            )
          "
        >
          <img
            src="../assets/icons/folder-3-fill.svg"
            alt=""
            v-if="this.$route.path == '/ministry-documents'"
          />
          <img
            src="../assets/icons/document-icon.svg"
            alt="document-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[7] }}
          </span>
        </router-link>
      </li>
      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name === 'VIEWER' &&
          fields['document_reports'] &&
          canAccessMeetings
        "
        class="dropdown"
        id="dropdown"
      >
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/log-reports"
          exact
        >
          <img
            src="../assets/icons/report.png"
            alt=""
            v-if="this.$route.path == '/log-reports'"
          />
          <img
            src="../assets/icons/report_2.png"
            alt="appointment-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[8] }}
          </span>
        </router-link>
      </li>
      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name === 'ADMIN'
        "
        class="dropdown"
        id="dropdown"
      >
        <h6 class="link-divider">{{ linkDivider[2] }}</h6>
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/all-users"
          exact
        >
          <img
            src="../assets/icons/active-user.svg"
            alt="appointment-icon"
            v-if="this.$route.path == '/all-users'"
          />

          <img src="../assets/icons/user.svg" alt="home-icon" v-else />
          <span class="menu-link">
            {{ menuLink[2] }}
          </span>
        </router-link>
      </li>
      <li
        v-if="
          $store.state.auth.user &&
          $store.state.auth.user.roles[0].name === 'ADMIN'
        "
        class="dropdown"
        id="dropdown"
      >
        <h6 class="link-divider">{{ linkDivider[3] }}</h6>
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/all-ministries"
          exact
        >
          <img
            src="../assets/icons/active-ministry.svg"
            alt="Institution-icon"
            v-if="this.$route.path == '/all-ministries'"
          />
          <img
            src="../assets/icons/ministry.svg"
            alt="Institution-icon"
            v-else
          />
          <span class="menu-link">
            {{ menuLink[3] }}
          </span>
        </router-link>

        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/all-clusters"
          exact
        >
          <img
            src="../assets/icons/active-cluster.svg"
            alt="Cluster-icon"
            v-if="this.$route.path == '/all-clusters'"
          />
          <img src="../assets/icons/cluster.svg" alt="User-icon" v-else />
          <span class="menu-link">
            {{ menuLink[4] }}
          </span>
        </router-link>
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/settings"
          exact
        >
          <img
            src="../assets/icons/active-settings.svg"
            alt="Cluster-icon"
            v-if="this.$route.path == '/settings'"
          />
          <img src="../assets/icons/settings.svg" alt="User-icon" v-else />
          <span class="menu-link">
            {{ menuLink[6] }}
          </span>
        </router-link>
      </li>
      <li
        class="dropdown"
        id="dropdown"
        v-if="
          $store.state.auth.user &&
          (($store.state.auth.user.projectRoles && canAccessProjects) ||
            $store.state.auth.user.roles[0].name === 'ADMIN')
        "
      >
        <h6 class="link-divider">{{ linkDivider[4] }}</h6>
        <div class="w-full">
          <div class="link-item" @click="isDropdownOpen = !isDropdownOpen">
            <img
              src="../assets/icons/calendar-icon.svg"
              alt="appointment-icon"
            />
            <span class="menu-link">
              {{ menuLink[10] }}
            </span>
            <svg
              data-testid="dropdown-right-icon"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="`${
                isDropdownOpen ? '' : 'rotate-180'
              } transition-transform duration-300`"
            >
              <path
                d="M12.3552 9.52217L12.3552 9.52218L12.3587 9.52569L16.6412 13.7582C16.684 13.8051 16.7175 13.8598 16.7398 13.9192C16.7631 13.9811 16.7739 14.0469 16.7716 14.1129C16.7692 14.1789 16.7539 14.2438 16.7263 14.3039C16.6987 14.3639 16.6596 14.4179 16.611 14.4627L16.6044 14.4688L16.5981 14.4751C16.5514 14.5214 16.496 14.5581 16.435 14.583C16.3741 14.6078 16.3089 14.6205 16.2431 14.6201L16.2423 14.6201C16.1114 14.6195 15.986 14.5677 15.8929 14.4757C15.8927 14.4755 15.8925 14.4753 15.8923 14.4751L12.3537 10.9365L12.0002 10.583L11.6466 10.9365L8.10769 14.4755C8.10754 14.4756 8.10739 14.4758 8.10724 14.4759C8.01361 14.5688 7.88708 14.6209 7.75519 14.6209C7.6231 14.6209 7.49637 14.5686 7.40269 14.4755L7.40229 14.4751C7.35543 14.4286 7.31823 14.3733 7.29285 14.3124C7.26746 14.2514 7.25439 14.1861 7.25439 14.1201C7.25439 14.0541 7.26746 13.9887 7.29285 13.9278C7.31823 13.8668 7.35543 13.8115 7.40229 13.7651L7.4023 13.7651L7.40375 13.7636L11.6437 9.52362L11.6437 9.52362L11.6452 9.52217C11.6917 9.4753 11.747 9.43811 11.8079 9.41272C11.8688 9.38734 11.9342 9.37427 12.0002 9.37427C12.0662 9.37427 12.1316 9.38734 12.1925 9.41272C12.2534 9.43811 12.3087 9.4753 12.3552 9.52217Z"
                fill="black"
                :class="`${
                  isDropdownOpen ? 'stroke-white' : 'stroke-gray-400'
                }`"
              />
            </svg>
          </div>

          <ul
            :class="`border-l-2 border-gray-300 pl-3 ml-5 text-gray-400 capitalize font-medium text-sm cursor-pointer`"
            v-show="isDropdownOpen"
          >
            <router-link
              v-for="(item, index) in projectSubLinks"
              :key="index"
              tag="li"
              class="link-item colored-link"
              :to="item.subLinks ? '#' : item.link"
              exact
              :style="`padding-left: 0; padding-right: 0; ${
                item.name == 'Cabinet Log'
                  ? 'background-color: white; color: inherit;'
                  : ''
              }`"
            >
              <div>
                <div class="flex">
                  <span
                    class="menu-link"
                    style="padding-left: 5px"
                    @click="
                      () => {
                        if (item.subLinks) {
                          item.isDropdownOpen = !item.isDropdownOpen;
                        }
                      }
                    "
                  >
                    {{ item.name }}
                  </span>

                  <div
                    v-if="item.subLinks"
                    class="ml-2"
                    @click="item.isDropdownOpen = !item.isDropdownOpen"
                  >
                    <svg
                      data-testid="dropdown-right-icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      :class="`${
                        item.isDropdownOpen ? '' : 'rotate-180'
                      } transition-transform duration-300`"
                    >
                      <path
                        d="M12.3552 9.52217L12.3552 9.52218L12.3587 9.52569L16.6412 13.7582C16.684 13.8051 16.7175 13.8598 16.7398 13.9192C16.7631 13.9811 16.7739 14.0469 16.7716 14.1129C16.7692 14.1789 16.7539 14.2438 16.7263 14.3039C16.6987 14.3639 16.6596 14.4179 16.611 14.4627L16.6044 14.4688L16.5981 14.4751C16.5514 14.5214 16.496 14.5581 16.435 14.583C16.3741 14.6078 16.3089 14.6205 16.2431 14.6201L16.2423 14.6201C16.1114 14.6195 15.986 14.5677 15.8929 14.4757C15.8927 14.4755 15.8925 14.4753 15.8923 14.4751L12.3537 10.9365L12.0002 10.583L11.6466 10.9365L8.10769 14.4755C8.10754 14.4756 8.10739 14.4758 8.10724 14.4759C8.01361 14.5688 7.88708 14.6209 7.75519 14.6209C7.6231 14.6209 7.49637 14.5686 7.40269 14.4755L7.40229 14.4751C7.35543 14.4286 7.31823 14.3733 7.29285 14.3124C7.26746 14.2514 7.25439 14.1861 7.25439 14.1201C7.25439 14.0541 7.26746 13.9887 7.29285 13.9278C7.31823 13.8668 7.35543 13.8115 7.40229 13.7651L7.4023 13.7651L7.40375 13.7636L11.6437 9.52362L11.6437 9.52362L11.6452 9.52217C11.6917 9.4753 11.747 9.43811 11.8079 9.41272C11.8688 9.38734 11.9342 9.37427 12.0002 9.37427C12.0662 9.37427 12.1316 9.38734 12.1925 9.41272C12.2534 9.43811 12.3087 9.4753 12.3552 9.52217Z"
                        fill="black"
                        :class="`${
                          item.isDropdownOpen
                            ? 'stroke-white'
                            : 'stroke-gray-400'
                        }`"
                      />
                    </svg>
                  </div>
                </div>

                <ul
                  :class="`border-l-2 border-gray-300 pl-3 ml-2 text-gray-400 capitalize font-medium text-sm cursor-pointer`"
                  v-show="item.isDropdownOpen"
                >
                  <router-link
                    v-for="(_item, index) in item.subLinks"
                    :key="index"
                    tag="li"
                    class="link-item colored-link"
                    class-active="active"
                    :to="_item.link"
                    exact
                    style="padding-left: 0; padding-right: 0"
                  >
                    <span class="menu-link" style="padding-left: 5px">
                      {{ _item.name }}
                    </span>
                  </router-link>
                </ul>
              </div>
            </router-link>
          </ul>
        </div>

        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/users-project-roles"
          exact
          v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
        >
          <img
            src="../assets/icons/active-user.svg"
            alt="appointment-icon"
            v-if="this.$route.path == '/users-project-roles'"
          />

          <img src="../assets/icons/user.svg" alt="home-icon" v-else />
          <span class="menu-link">
            {{ menuLink[11] }}
          </span>
        </router-link>
        <router-link
          tag="li"
          class="link-item colored-link"
          class-active="active"
          to="/sectors"
          exact
          v-if="$store.state.auth.user.roles[0].name === 'ADMIN'"
        >
          <img
            src="../assets/icons/active-settings.svg"
            alt="appointment-icon"
            v-if="this.$route.path == '/users-project-roles'"
          />

          <img src="../assets/icons/settings.svg" alt="home-icon" v-else />
          <span class="menu-link">
            {{ menuLink[12] }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Api from "/src/services/Apis";

export default {
  name: "Sidebar",
  data() {
    return {
      fields: [],
      linkDivider: [
        "MINICAAF meetings",
        "Cabinet Log",
        "Users",
        "System setup",
        "Categories",
      ],
      menuLink: [
        "Profiles",
        "Documents",
        "Users",
        "Institutions",
        "Clusters",
        "Profile Reports",
        "System settings",
        "Ministry Documents",
        "Cabinet Log Reports",
        "Gazette Processing Documents",
        "Categories",
        "User Project Roles",
        "Sectors",
      ],
      projectSubLinks: [
        {
          name: "Cabinet Log",
          isDropdownOpen: this.$route.path.includes("pcas") || this.$route.path.includes("papers"),
          subLinks: [
            {
              name: "PCAs",
              link: "/projects/pcas",
            },
            {
              name: "Cabinet Paper",
              link: "/projects/papers",
            },
          ],
        },
        {
          name: "Priority Projects",
          link: "/projects/priority-projects",
        },
        {
          name: "Presidential Pledges",
          link: "/projects/presidential-pledges",
        },
        {
          name: "PMO Meeting / StockTakes",
          link: "/projects/pmo-meeting",
        },
        {
          name: "Closed Files",
          link: "/projects/closed",
        },
      ],
      status: false,
      canAccessDocuments: false,
      canAccessMeetings: false,
      canAccessProjects: false,
      isDropdownOpen: this.$route.path.includes("projects"),
    };
  },
  computed: {
    ...mapGetters("sidebar", { state: "visible" }),
  },
  methods: {
    ...mapActions({ getFields: "settings/getAllowedFields" }),
    AppointmentDropdown() {
      let appoint = document.getElementById("appoint");
      if (!this.status) {
        document
          .getElementById("appointdropicon")
          ?.classList.remove("fa-caret-right");
        document
          .getElementById("appointdropicon")
          ?.classList.add("fa-caret-down");
        appoint?.classList.remove("hidden");
      } else {
        document
          .getElementById("appointdropicon")
          ?.classList.remove("fa-caret-down");
        document
          .getElementById("appointdropicon")
          ?.classList.add("fa-caret-right");
        appoint?.classList.add("hidden");
      }
      this.status = !this.status;
    },

    DocumentDropdown() {
      let doc = document.getElementById("docs");
      if (!this.status) {
        document
          .getElementById("docsdropicon")
          ?.classList.remove("fa-caret-right");
        document.getElementById("docsdropicon")?.classList.add("fa-caret-down");
        doc?.classList.remove("hidden");
      } else {
        document
          .getElementById("docsdropicon")
          ?.classList.remove("fa-caret-down");
        document
          .getElementById("docsdropicon")
          ?.classList.add("fa-caret-right");
        doc?.classList.add("hidden");
      }
      this.status = !this.status;
    },
    checkDocumentAccess() {
      Api.get("projects/role/user").then((response) => {
        if (response.data.data) {
          this.canAccessDocuments = response.data.data.canAccessDocuments;
          this.canAccessMeetings = response.data.data.canAccessMeetings;
          this.canAccessProjects = response.data.data.canAccessProjects;
        }
      });
    },
  },
  async created() {
    this.fields = await this.getFields("viewer_sidebar_links");
    this.checkDocumentAccess();
  },
};
</script>

<style lang="scss">
.my-sidebar {
  padding: 24px 14px 0;
  background: #ffffff;
  box-shadow: 5px 0px 9px 5px rgba(0, 0, 0, 0.02);
  width: 205px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  height: calc(100vh - 56px);
  overflow: auto;
  text-align: center;
  z-index: 9;

  color: #032b3d;

  #logo {
    // padding-left: 51px;
    img {
      width: 75px;
      height: 81px;
    }
    margin-bottom: 36px;
  }
  .link-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    img {
      width: 18px;
      height: 18px;
    }
    span {
      padding-left: 11.92px;
    }
  }
  .link-divider {
    text-align: left;
    color: #bcbdbe;
    margin-top: 33.05px;
    margin-bottom: 24.56px;
  }
  .dropdown-menu a {
    padding: 10px 40px;
    display: block;
  }
}

// .colored-link:hover,
// .dropdown-menu a:hover {
//   background-color: #4296bd8f;
//   color: #ffffff;
// }
.colored-link.router-link-exact-active,
.dropdown-menu a.router-link-exact-active {
  background-color: #4296bd;
  color: #fff;
}
</style>
