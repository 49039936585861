<template>
  <div v-if="$store.state.auth.user" class="nav-content flex">
    <div class="imigongo" style="background-image: url(/icons/nav-bg.svg)">
      <!-- <img src="/icons/nav-bg.svg" alt="" class="imigongo" /> -->
    </div>
    <h1 class="tms-heading text-center">PRIMATURE</h1>
    <div class="flex justify-end ml-auto place-items-center">
      <div
        class="toogle-sidebar lg:hidden flex place-items-center justify-center"
        @click="toogle()"
      >
        <svg
          v-if="state"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 18V20H3V18H21ZM6.596 3.90399L8.01 5.31799L4.828 8.49999L8.01 11.682L6.596 13.096L2 8.49999L6.596 3.90399ZM21 11V13H12V11H21ZM21 3.99999V5.99999H12V3.99999H21Z"
            fill="#28A4E2"
          />
        </svg>

        <svg
          v-else
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 18V20H3V18H21ZM17.404 3.90399L22 8.49999L17.404 13.096L15.99 11.682L19.172 8.49999L15.99 5.31799L17.404 3.90399ZM12 11V13H3V11H12ZM12 3.99999V5.99999H3V3.99999H12Z"
            fill="#28A4E2"
          />
        </svg>
      </div>

      <div
        class="ml-auto mr-4"
      >
        <Notifications />
      </div>

      <Avatar
        v-if="$store.state.auth.user"
        :fullname="
          `${$store.state.auth.user.firstName}` +
          ' ' +
          `${$store.state.auth.user.lastName}`
        "
        color="#054D6F"
        :size="30"
        @click.native="displayUserDropdown()"
        class="mt-1 sm:mt-2 avatar cursor-pointer"
        id="prof-avatar"
      ></Avatar>

      <div
        class="user-dropdown custom-scroll"
        v-if="isNotificationOpen == 3"
        id="profile-card"
      >
        <div class="user-credentials">
          <h1>
            {{
              $store.state.auth.user.firstName +
              "" +
              $store.state.auth.user.lastName
            }}
          </h1>
          <p>{{ $store.state.auth.user.roles[0].name }}</p>
        </div>
        <div class="account-settings">
          <div class="account">
            <img src="/icons/setting.svg" alt="" />
            <h2 class="cursor-pointer" @click="editUser">Account Settings</h2>
          </div>

          <div class="logout" @click="doLogout">
            <img src="/icons/logout.svg" alt="" />
            <h2>Logout</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Avatar from "vue-avatar-component";
import Notifications from "./Notifications.vue";
export default {
  components: {
    Avatar,
    Notifications,
  },
  data() {
    return {
      isNotificationOpen: 1,
      count: 0,
    };
  },
  computed: {
    ...mapGetters("sidebar", { state: "visible" }),
  },
  methods: {
    ...mapMutations("sidebar", { toogle: "TOOGLE_SIDEBAR_VISIBILITY" }),
    ...mapActions({ logout: "auth/logout" }),
    doLogout() {
      this.logout();
    },
    displayUserDropdown() {
      this.isNotificationOpen = 3;
      this.count++;
      if (this.count > 1) {
        this.isNotificationOpen = 1;
        this.count = 0;
      }
    },
    editUser() {
      this.$store.commit("auth/setSelectedUser", this.$store.state.auth.user);
      this.$router.push(
        `/update-account/${this.$store.state.auth.user.id}/user-identify`
      );
    },
  },

  created() {
    document.addEventListener("click", (event) => {
      const specifiedElement = document.getElementById("profile-card");
      const avatar = document.getElementById("prof-avatar");

      if (!specifiedElement) return;

      const isClickInside = specifiedElement.contains(event.target);
      const isClickOnAvatar = avatar.contains(event.target);

      if (isClickInside) {
        if (isClickOnAvatar) {
          this.isNotificationOpen = 3;
        }
      } else {
        if (!isClickOnAvatar) this.isNotificationOpen = 1;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.nav-content {
  background: #1679a8;
  display: flex;
  // justify-content: flex-end;
}
.imigongo {
  height: 44px;
  width: 683px;
}
.tms-heading {
  position: absolute;
  z-index: 1;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0.2rem 0 0 3.5rem;
}
//   width: 45px;
//   object-fit: cover;
//   height: 45px;
//   border-radius: 50%;
.avatar {
  margin-right: 44px;
}
.notification {
  width: 1.3rem;
  height: 2rem;
  padding: 1rem 0 0 0;
  margin-top: -7px;
  margin-right: 2rem;
}
.notification-alert {
  width: 1rem;
  height: 1rem;
  position: absolute;
  background: #28a4e2;
  margin: 0 0 0 0.5rem;
  border-radius: 50%;
  z-index: 1;
}
.notification-alert span {
  color: white;
  font-size: 12px;
  position: absolute;
  margin: 0 0 0 -0.2rem;
}
.user-dropdown {
  position: absolute;
  width: 14rem;
  margin-top: 300px;
  height: 16rem;
  background: white;
  z-index: 9;
  margin-right: 1.6rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.notification-dropdown {
  background: white;
  height: 16rem;
  position: absolute;
  z-index: 1;
  margin-right: 7rem;
  margin-top: 300px;
  width: 19rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &::-webkit-scrollbar {
    width: 8px;
    padding: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #ebf2fa;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d4d9df;
    border-radius: 2px;
  }
}
.account {
  display: flex;
  padding: 1rem 0 0.4rem 0;
  cursor: pointer;
}
.account h2 {
  margin: 0 0 0 1rem;
}
.logout {
  display: flex;
  padding: 1rem 0 0.4rem 0;
  cursor: pointer;
}
.logout h2 {
  margin: 0 0 0 1rem;
}
.user-credentials {
  padding: 13px 11px;
  text-align: initial;
  background: #fafcfe;
  // margin-left: 10px;

  h1 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;

    color: #032b3d;
    margin-bottom: 2px;
  }
  p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;

    color: #b0b0b0;
  }
}
.account-settings {
  padding: 1rem 0 0 1rem;
}
.interview-section {
  display: flex;
  padding: 0 0 0 1.5rem;
  width: 16rem;
}
.single-notification {
  display: flex;
  padding: 20px 13px 0 13px;
}
.notification-dropdown {
  overflow-y: auto;
}
.message-bg {
  width: 2.6rem;
  height: 2.6rem;
  background: #1679a8;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}
.message-image {
  padding: 0.5rem 0 0 0.6rem;
  width: 2rem;
  height: 34px;
}

.interview-details h1 {
  font-weight: 600;
  font-size: 15px;
}
.interview-details p {
  font-size: 14px;
  color: #b0b0b0;
}
.notification-timeline {
  color: #b0b0b0;
  font-size: 15px;
  display: flex;
}
.notification-timeline div {
  font-size: 32px;
  color: #c4c4c4;
  margin: -1.3rem 0 0 0;
  font-weight: 700;
}
.toogle-sidebar {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 3px;
  background: #fffffe;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  left: 9px;
}
@media (max-width: 550px) {
  .tms-heading {
    margin-left: 7.6rem;
  }
}
</style>
